import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Lore.module.scss";
import icon from "../assets/images/notebook12.svg"

import lore1 from '../assets/images/lore-1.jpg'
import lore2 from '../assets/images/lore-2.jpg'
import lore3 from '../assets/images/lore-3.jpg'

const Lore: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.loreDiv}>
        <div className={styles.loreBodyDiv}>
          <div className={styles.loreContainerDiv}>
            <div className={styles.pDiv}>
              <p className={styles.eXOsStandAlone}>Aurora once gleamed as humanity's crowning achievement—a world of soaring towers and perfect skies, all powered by the miraculous metal Mobidium. This superconductive material fueled an age of unprecedented prosperity, flowing abundantly from the planet's depths. But as the veins of Mobidium thinned, the Aurora Mining Corporation tightened its grip on remaining reserves. Cooperation gave way to rivalry, then to open conflict, as civil wars erupted over the dwindling resource.</p>
              
              <p className={styles.eXOsStandAlone}>It was amid this chaos that the Nyxx arrived—silent and terrible on massive blood-red asteroids that crashed into Aurora's surface. Within days, these photonic parasites spread their infection across entire provinces, twisting beautiful architecture beneath a creeping lattice of alien crystal and mutated biocry. People, animals, even the soil itself were consumed and perverted into extensions of the Nyxx's will, leaving devastation in their wake.</p>
              
              <div className={styles.imageContainer}>
                <img src={lore1} className={styles.centeredImage} alt="Aurora under attack" />
              </div>
              <br />
              <br />
              
              <p className={styles.eXOsStandAlone}>Desperate to fight back, the military deployed their secret weapon: the EXOs, cybernetically enhanced soldiers immune to Nyxx corruption. Initially, they sparked hope with their mechanical efficiency, but the Nyxx multiplied too quickly. As civilization collapsed, General Xeon proposed the unthinkable—the Carnage Event, a scorched-earth policy to obliterate all infected zones with nuclear weapons. The EXOs marched forward with their terrible arsenal, but as the air filled with terror and the smell of burning flesh, something unexpected happened deep within their augmented minds: the EXOs hesitated.</p>
              
              <div className={styles.imageContainer}>
                <img src={lore2} className={styles.centeredImage} alt="EXOs in combat" />
              </div>
              <br />
              <br />
              
              <p className={styles.eXOsStandAlone}>Across Aurora's ruined landscapes, the EXOs broke rank at the height of the Carnage Event. Some spark of conscience had ignited within their cybernetic minds, and they refused to detonate their weapons of mass destruction. General Xeon, shaken by the horrific toll and moved by the EXOs' newfound empathy, commanded his forces to stand down. His voice cracked with regret as he took personal charge of the remaining troops, determined to find another way to defeat the Nyxx.</p>
              
              <p className={styles.eXOsStandAlone}>Under Xeon's leadership, soldiers, civilians, and the awakened EXOs rallied as one. They launched a desperate counteroffensive, fighting through corridors of broken skyscrapers and over fields crusted with red crystalline shards. Civilians piloted mining mechs against towering Nyxx mutations while brave soldiers lured the invaders into EXO killing fields. The general himself led many sorties, inspiring his forces with brilliant strategies. When the battle finally ended, the Nyxx nests were smashed, their crimson crystals shattered into dust—but Aurora lay utterly devastated.</p>
              
              <div className={styles.imageContainer}>
                <img src={lore3} className={styles.centeredImage} alt="The journey to Dremica" />
              </div>
              <br />
              <br />
              
              <p className={styles.eXOsStandAlone}>The survivors fled to Nova, Aurora's lifeless moon, where they huddled in cramped modules breathing recycled air. Yet hope emerged when deep-space probes detected a distant world—Dremica—rich with Mobidium. The colony ship Helios One was launched with handpicked settlers, but after four years, their messages went silent. With Nova's resources dwindling, a second expedition was hastily prepared. Engineers worked day and night to forge a new ship, this time carrying troops, EXOs, and General Xeon himself—now a legendary hero determined to protect what remained of humanity. As engines roared and lift-off shook Nova's desolate plains, humanity dared to dream of salvation once again.</p>
              
              <p className={styles.joinEVAIn1}>Join the expedition to Dremica.</p>
            </div>
            <div className={styles.cTAsDiv}>
              <button className={styles.button1} onClick={() => window.open('https://opensea.io/collection/exosama-expect-chaos')}>
                <img
                  className={styles.notebookIcon}
                  alt=""
                  src="../sailboat1.svg"
                />
                <b className={styles.rEADWIKIB2}>VIEW COLLECTION</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lore;
