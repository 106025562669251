import { Link, useNavigate } from "react-router-dom";
import styles from "../pages/Home.module.scss";
import { useCallback, useState } from "react";

type Props = {
  setMobileDrawerOpen: any,
}

const NavBar = ({ setMobileDrawerOpen }: Props) => {
  const navigate = useNavigate();
  const onGhostButtonClick = useCallback(() => {
    navigate("/lore");
  }, [navigate]);

  const openMobileDrawer = useCallback(() => {
    setMobileDrawerOpen(true);
  }, []);


  return <div className={styles.navbarDiv}>
    <Link className={styles.exoLogoA} to="/">
      <img className={styles.exoLogoIcon} alt="" src="../exo-logo.svg" />
    </Link>
    <div className={styles.menuDiv}>
      <div className={styles.linksDiv}>
        <button
          className={styles.ghostButton}
          onClick={() => window.open('https://dremica.com')}
        >
          <img
            className={styles.notebookIcon}
            alt=""
            src="../notebook.svg"
          />
          <b className={styles.rEADWIKIB}>ENTER DREMICA</b>
        </button>
        <button
          className={styles.ghostButton}
          onClick={onGhostButtonClick}
        >
          <img
            className={styles.notebookIcon}
            alt=""
            src="../notebook.svg"
          />
          <b className={styles.rEADWIKIB}>THE LORE</b>
        </button>
        <button className={styles.ghostButton} onClick={() => window.open('https://opensea.io/collection/exosama-expect-chaos')}>
          <img
            className={styles.notebookIcon}
            alt=""
            src="../notebook.svg"
          />
          <b className={styles.rEADWIKIB}>VIEW COLLECTION</b>
        </button>
      </div>
      <button
        className={styles.hamburgerButton}
        onClick={openMobileDrawer}
      >
        <img className={styles.menu2Icon} alt="" src="../menu2.svg" />
        <b className={styles.rEADWIKIB3}>{` `}</b>
      </button>
    </div>
  </div>
}

export default NavBar